<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-23 10:58:25
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-08-02 15:27:00
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\Lunbo\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home-hero-container container">
    <el-form ref="form" :model="form" label-width="80px">
  <el-form-item label="活动名称">
    <el-input v-model="form.name"></el-input>
  </el-form-item>
  <el-form-item label="活动区域">
    <el-select v-model="form.region" placeholder="请选择活动区域">
      <el-option label="区域一" value="shanghai"></el-option>
      <el-option label="区域二" value="beijing"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="活动时间">
    <el-col :span="11">
      <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
    </el-col>
    <el-col class="line" :span="2">-</el-col>
    <el-col :span="11">
      <el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;"></el-time-picker>
    </el-col>
  </el-form-item>
  <el-form-item label="即时配送">
    <el-switch v-model="form.delivery"></el-switch>
  </el-form-item>
  <el-form-item label="活动性质">
    <el-checkbox-group v-model="form.type">
      <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
      <el-checkbox label="地推活动" name="type"></el-checkbox>
      <el-checkbox label="线下主题活动" name="type"></el-checkbox>
      <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
    </el-checkbox-group>
  </el-form-item>
  <el-form-item label="特殊资源">
    <el-radio-group v-model="form.resource">
      <el-radio label="线上品牌商赞助"></el-radio>
      <el-radio label="线下场地免费"></el-radio>
    </el-radio-group>
  </el-form-item>
  <el-form-item label="活动形式">
    <el-input type="textarea" v-model="form.desc"></el-input>
  </el-form-item>
  <el-form-item>
    <VueEditor
   :config="config"
   ref="vueEditor"  
 ></VueEditor>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="onSubmit">立即创建</el-button>
    <el-button>取消</el-button>
  </el-form-item>
</el-form>
  </div>
</template>

<script>
import * as fs from "fs";
import { Input } from "element-ui";
import VueEditor from "vue-word-editor";
import "quill/dist/quill.snow.css";
import { Util, tkk3DEngine } from "../../util/tkk3DEngine/importBundle";

export default {
   components: {
    VueEditor,
  },
  data() {
    return {
      config: {
        uploadImage: {
        //   url: axios.defaults.baseURL + "/upload", //这是我的接口路径(改成你自己的就行了)
        //   name: "file", // 后台所需要的参数的名称
        //   headers: { Authorization: localStorage.getItem("token") }, //设置请求头信息
          // res是结果，insert方法会把内容注入到编辑器中，res.data.url是资源地址
          uploadSuccess(res, insert) {
            insert(axios.defaults.baseURL + res.data.data.url); 
            //文件上传之后的回调，接收服务器响应回来的数据，根据服务器的地址进行填充
          },
        },
        // 上传视频的配置与图片上传大同小异
        uploadVideo: {
          // url: "http://localhost:3000/upload",
          // name: "file",
          // headers: { Authorization: localStorage.getItem("token") },//设置请求头信息
          uploadSuccess(res, insert){
            insert("http://localhost:3000" + res.data.url)
          }
        }
      },
      form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        }
        
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.home-hero-container {
  position: relative;
  z-index: 10;

  .home-hero {
    height: 94vh;
  }
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.row-canvas {
  width: 100%;
  background-color: aqua;
  height: 94vh;
  border-radius: 10px;
}
.row-texts {
  position: absolute;
  top: 5px;
  left: 0;
  font-size: 30px;
  width: 100%;
  color: rgba(91, 132, 255, 1);
  font-weight: 700;
  text-align: center;
  z-index: 10;
  // background: #f5f5f5;
}
.row-image {
  position: absolute;
  top: 80vh;
  left: 20vw;
  width: 60vw;

  display: flex;
  justify-content: space-around;
}
.modelImage {
  width: 100px;
  height: 100px;
  background-color: green;
  border: 1px black solid;
}
.row-table {
  display: flex;
  // border: 1px solid #000;
  width: 15%;
  justify-content: space-between;
  position: relative;
  top: -80vh;
  left: 80vw;
}
.row-table li {
  text-align: center;
  padding: 5px;
  background: rgb(255, 106, 0);
  // counter-reset: #ffffff;
  border-radius: 5px;
  margin-top: 5px;
  color: #ffffff;
}
li {
  list-style-type: none;
}
</style>